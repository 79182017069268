import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { close } from "../../redux";

import "./style.css"

const title = {
    fr: `Gestion des cookies`,
    en: `Cookie management`,
    sp: `Gestión de cookies`,
    de: `Cookie-Verwaltung`
};
const text = {
    fr: `Les cookies sont essentiels au bon fonctionnement de Pro-Vide, facilitant des fonctionnalités telles que le panier d'achat 
    et la connexion. Pour une expérience optimale, nous vous encourageons à accepter les cookies. Si vous préférez ne pas les 
    utiliser, veuillez vous inscrire pour accéder pleinement à nos services. 
    
    Votre vie privée est notre priorité, ainsi les informations des cookies sont traitées conformément à notre`,
    en: `Cookies are essential for Pro-Vide to function properly, facilitating features such as the shopping cart 
    and connection. For the best experience, we encourage you to accept cookies. If you prefer not to 
    use, please register to fully access our services. 
    
    Your privacy is our priority, so cookie information is processed in accordance with our`,
    sp: `Las cookies son esenciales para el correcto funcionamiento de Pro-Vide, facilitando funciones como el carrito de la compra 
    y conexión. Para disfrutar de la mejor experiencia, le recomendamos que acepte cookies. Si prefieres no hacerlo 
    uso, regístrese para acceder completamente a nuestros servicios. 
    
    Su privacidad es nuestra prioridad, por lo que la información de las cookies se procesa de acuerdo con nuestra`,
    de: `Cookies sind für die ordnungsgemäße Funktion von Pro-Vide unerlässlich und ermöglichen Funktionen wie den Warenkorb 
    und Verbindung. Für ein optimales Erlebnis empfehlen wir Ihnen, Cookies zu akzeptieren. Wenn Sie es lieber nicht möchten 
    Bitte registrieren Sie sich, um uneingeschränkt auf unsere Dienste zugreifen zu können. 
    
    Ihre Privatsphäre hat für uns Priorität, daher werden Cookie-Informationen in Übereinstimmung mit unseren verarbeitet`
};
const button_policy = {
    fr: `politique de confidentialité`,
    en: `privacy Policy`,
    sp: `política de confidencialidad`,
    de: `datenschutzrichtlinie`
};
const button_login = {
    fr: `M'inscrire`,
    en: `Sign-in`,
    sp: `Registro`,
    de: `Registrieren`
};
const button_accept = {
    fr: `J'accepte`,
    en: `I accept`,
    sp: `Acepto`,
    de: `Ich akzeptiere`
};

const suite = {
    fr: `continuer sans accepter`,
    en: `continue without accepting`,
    sp: `continuar sin aceptar`,
    de: `ohne zu akzeptieren fortfahren`
}

function Cookies() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const lang = useSelector(state => state.menu.lang);

    const [cookiesAccepted, setCookiesAccepted] = useState(sessionStorage.getItem('cookies') || false);

    function goPage(route) {
        window.scrollTo(0, 0);
        dispatch(close());

        navigate(`/${route}`);
    }

    return !cookiesAccepted && location.pathname !== '/login' && (
        <div className='cookies'>
            <div className="content">
                <span>{title[lang]}</span>
                <p>{text[lang]}<button>{button_policy[lang]}</button>.</p>
                <div className="container">
                    <button onClick={() => goPage('login')} className="red">{button_login[lang]}</button>
                    <button
                        className="blue"
                        onClick={() => {
                            setCookiesAccepted(true);
                            sessionStorage.setItem('cookies', true);
                        }}
                    >{button_accept[lang]}</button>
                </div>

                <button
                    className='continue'
                    onClick={() => {
                        setCookiesAccepted(true);
                        sessionStorage.setItem('cookies', true);
                    }}
                >{suite[lang]}</button>
            </div>
        </div>
    )
}

export default Cookies;